import React from "react";

export default function About(){
    return(
        <div className={"about"}>
            <h2>À propos</h2>
            <p className={"about__text"}>
                Je suis un développeur avec un penchant créatif.
                Mes différents side-projects témoigneront de mes intérêts variés et de mon ambition à créer.
                J'ai travaillé dans le domaine du jeu vidéo, du VR, des applications web, autant backend que frontend et suis toujours curieux d'apprendre.
            </p>
        </div>
    )
}