import React from "react";
import githubLogo from "./Font Awesome Free/Brands/github.svg"
import linkedinLogo from "./Font Awesome Free/Brands/linkedin.svg"
import twitterLogo from "./Font Awesome Free/Brands/twitter.svg"

export default function Footer(){
    return(
        <div className={"footer"}>
            <div className={"footer__button-row"}>
                <a className="footer__button" href={"https://github.com/DaGitA"}>
                    <img  src={githubLogo} alt={"github logo"}/>
                </a>
                <a className="footer__button" href={"https://www.linkedin.com/in/david-arel-7b507060/"}>
                    <img src={linkedinLogo} alt={"linkedin logo"}/>
                </a>
                <a className="footer__button" href={"https://twitter.com/Deha2"}>
                    <img src={twitterLogo} alt={"twitter logo"}/>
                </a>
            </div>
        </div>
    )
}