import React from "react";
import avatar from "./avatar.jpg";
import mailIcon from "./mail_icon.png"

export default function Info(){
    return(
        <div className={"info"}>
            <img src={avatar} alt={"dev avatar"}/>
            <h1>David Arel</h1>
            <p className={"info__title"}>Développeur</p>
            <a className={"info__email"} href={"mailto:david.arel@outlook.com"}>
                <button>
                    <img className={"info__emailIcon"} src={mailIcon} alt={"mail icon"}/>
                    <span>Courriel</span>
                </button>
            </a>
        </div>
    )
}