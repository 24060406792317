import React from "react";

export default function Interests(){
    return(
        <div className={"interests"}>
            <h2>Intérêts</h2>
            <p className={"interests__text"}>
                Technologie, entrepreunariat, bootstrap/micro startups, photographie, vélo de toutes sortes, bande-dessinées, ski,
                lecture, dessin, histoire et géographie, jeux vidéo (bien que principalement Age of Empires).
                Je suis toujours partant pour philosopher autour d'un verre de whisky.
            </p>
        </div>
    )
}